export const OCCUPATIONS = [
  'Physician',
  'Nurse Practitioner',
  'Physician Assistant',
  'Medical Student',
  'NP Student',
  'PA Student',
  'Registered Nurse',
  'Pharmacist',
  'Dentist',
  'Other',
];

export const HOW_DID_YOU_HEAR = [
  'From a Physician Friend / Colleague',
  'From a Non-Physician Friend / Colleague',
  'Google / Search Engine',
  'Email',
  'Facebook',
  'Instagram',
  'TikTok',
  'Twitter',
  'Conference',
  'Other',
];

// Maintenance Mode
// maintenance mode variables and statuses are defined at https://vercel.com/xyla/~/stores
export const GLOBAL_MAINTENANCE_MODE = 'openevidence_maintenance_global';
export const US_ONLY_MAINTENANCE_MODE = 'openevidence_maintenance_us_only';
export const INTERNATIONAL_ONLY_MAINTENANCE_MODE =
  'openevidence_maintenance_international_only';

export const MAINTENANCE_MODE_TITLE = 'Down for Maintenance';
export const MAINTENANCE_MODE_MESSAGE =
  'OpenEvidence is currently down, but we are working to restore service as soon as we can. Thank you for your patience.';

// Errors
export const HIGH_ACTIVITY_ERROR_MSG =
  'Unusually high activity has been detected on your account. Please try again later or contact help@openevidence.com if you believe this is an error.';
export const THROTTLE_ERROR_MSG =
  'We are experiencing high demand and are temporarily limiting usage. Thank you for your understanding.';

export const SIGNUP_DISALLOWED_ERROR_MSG =
  'We are experiencing extremely high demand and are temporarily limiting registrations. Thank you for your patience.';

export const COUNTRY_UNAVAILABLE_MSG =
  'We’re sorry, OpenEvidence is not available in your country at this time.';

export const DEFAULT_ERROR_MSG =
  'We are experiencing exceptionally high demand. Please hang tight as we work on scaling our systems.';

export const TIMEOUT_ERROR_MSG =
  'Generating the answer took longer than expected. Please try again in a few moments.';

export const COMPUTATION_ERROR_MSG =
  'We are experiencing exceptionally high demand. Please hang tight as we work on scaling our systems.';

export const EXTERNAL_SEARCH_INTRO_MSG =
  'While we are unable to directly answer your question, here are some external resources that may provide helpful information:';

export const EXTERNAL_SEARCH_MSG_SEPARATOR = '\n\n\n';
export const UNBOXING_PREFIX = 'UNBOXING:';

export const HCP_VERIFICATION_ERROR_MSG =
  'Your HCP identifier could not be verified. Please ensure all entered information is correct and try again.';
