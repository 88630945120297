/* tslint:disable */
/* eslint-disable */
/**
 * OE User Profile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `hcp` - Hcp
 * * `patientOrCaregiver` - Patient Or Caregiver
 * * `patientPortal` - Patient Portal
 * * `student` - Student
 * @export
 */
export const RegistrationTypeEnum = {
    Hcp: 'hcp',
    PatientOrCaregiver: 'patientOrCaregiver',
    PatientPortal: 'patientPortal',
    Student: 'student'
} as const;
export type RegistrationTypeEnum = typeof RegistrationTypeEnum[keyof typeof RegistrationTypeEnum];


export function instanceOfRegistrationTypeEnum(value: any): boolean {
    for (const key in RegistrationTypeEnum) {
        if (Object.prototype.hasOwnProperty.call(RegistrationTypeEnum, key)) {
            if (RegistrationTypeEnum[key as keyof typeof RegistrationTypeEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function RegistrationTypeEnumFromJSON(json: any): RegistrationTypeEnum {
    return RegistrationTypeEnumFromJSONTyped(json, false);
}

export function RegistrationTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegistrationTypeEnum {
    return json as RegistrationTypeEnum;
}

export function RegistrationTypeEnumToJSON(value?: RegistrationTypeEnum | null): any {
    return value as any;
}

export function RegistrationTypeEnumToJSONTyped(value: any, ignoreDiscriminator: boolean): RegistrationTypeEnum {
    return value as RegistrationTypeEnum;
}

