/*
IMPORTANT: Changes in this file should be mirrored in the src/piapiac/xyla/django/openevidence_app/helpers.py file to ensure consistency.
*/
import { OEUserProfile } from '../OEUserProfileAPI/_generated/models/OEUserProfile';
import { RegistrationTypeEnum } from '../OEUserProfileAPI/_generated/models/RegistrationTypeEnum';
import { OEAccessRoleName } from '../types';

// UserInfo is what is actually stored in the session.
// It combines the relevant session data (id, email, permissions) with the user metadata.
// We now store the user metadata in the database in xyla/django/oe_user_profile/models.py
// and we fetch it to the session token in an auth0 post-login hook.
// We take the typing from the generated models in OEUserProfileAPI/_generated/models/OEUserProfile.ts
// These should be EXACTLY equivalent to the types when we manually defined them here.

// Type utility to convert "T | null" to "T | undefined"
type NullToUndefined<T> = T extends null
  ? undefined
  : T extends object
  ? {
      [K in keyof T]: NullToUndefined<T[K]>;
    }
  : T;

// Type utility to remove null from union types
type RemoveNull<T> = T extends null ? never : T;

// Convert OEUserProfile to use undefined instead of null
type CleanOEUserProfile = {
  [K in keyof OEUserProfile]: NullToUndefined<RemoveNull<OEUserProfile[K]>>;
};

export type UserInfo = CleanOEUserProfile & {
  id: string;
  email?: string;
  emailVerified?: boolean;
  picture?: string;
  bestDisplayName?: string | null;
  oeAccessLevel?: OEAccessRoleName | null; // access level is determined by the permissions
};

// ==============================

// Payloads are the data that is sent to the backend and then sent to mixpanel after some massaging.

export type HCPOrStudentUserDataPayload = Pick<
  UserInfo,
  | 'name'
  | 'occupation'
  | 'specialty'
  | 'referrer'
  | 'inviteCode'
  | 'medicalSchool'
  | 'expectedYearOfGraduation'
  | 'expectedMonthOfGraduationString'
  | 'registrationType'
  | 'hcpIdentifierType'
  | 'hcpIdentifierValue'
  | 'hasConsentedToPolicies'
  | 'hasVerificationFiles'
  | 'isBAASigned'
>;

export type PatientUserDataPayload = Pick<
  UserInfo,
  | 'name'
  | 'role'
  | 'patientCommunity'
  | 'askedDoctor'
  | 'referrer'
  | 'patientCommunitySubtype'
  | 'inviteCode'
  | 'registrationType'
  | 'hasConsentedToPolicies'
>;

// Union of all possible payloads, to satisfy type-checking
export type UserDataPayload =
  | HCPOrStudentUserDataPayload
  | PatientUserDataPayload;

export const isPatientUserDataPayload = (
  data: HCPOrStudentUserDataPayload | PatientUserDataPayload
): data is PatientUserDataPayload => {
  return (
    (data as PatientUserDataPayload).registrationType ===
    RegistrationTypeEnum.PatientOrCaregiver
  );
};
