//** Article interfaces */
export interface ArticleResponse<Attachment> {
  id: string;
  datetime_created: string;
  article_type: string;
  inputs: {
    history: ArticleHistory<Attachment>[];
    attachments: Attachment[];
    question: string;
    minimal_processing_mode: boolean;
    variant_configuration_file: string;
  };
  status: string;
  user_error_msg: string;
  output: ArticleOutput;
  partial_output: null;
  original_article: string;
  personalization_applied: boolean;
  access_level: string;
  shared_with_emails: string[];
  user_can_change_access_level: boolean;
}

export interface ArticleHistory<Attachment> {
  input: string;
  attachments: Attachment[];
  outputArticleData: ArticleData;
}

export interface ArticleOutput {
  id: string;
  text: string;
  structured_article: ArticleData;
  page_type: string;
}

export interface SnippetImageDetail {
  image_url: string;
  image_b64?: string;
}

export interface CitationDetail {
  title: string;
  authors_string: string;
  journal_name: string | null;
  journal_short_name: string | null;
  publication_info_string: string | null;
  doi: string | null;
  pmid: number | null;
  dt_published: string | null;
  dt_last_updated?: string | null;
  href: string;
  repository?: string;
  journal_owner?: string;
  image_data?: SnippetImageDetail;
  source_article_links?: string[] | null;
  special_processing_tenants?: string[] | null;
}

export interface CalloutCitedDocument {
  title: string;
  authors?: string;
  publication_info?: string;
  href?: string;
  published_date_text?: string;
}

/*-------------*/
/*  Citations  */
/*-------------*/

// NB(evan): This is a citation for legacy condition articles.
// Newer articles use the Citation type below.
export interface LegacyCitation {
  pmid: string;
  title: string;
  citation: string;
}

export interface SpanEntry {
  clinical_report?: LegacyCitation;
  clinical_trial: LegacyCitation;
  outcome: string | number;
}

export interface CitationScores {
  impact_score?: number;
  impact_score_type?: string;
  recency_score?: number;
  recency_score_type?: string;
  relevance_score?: number;
  relevance_score_type?: string;
  is_review?: boolean;
}

export interface SnippetDisplayData {
  text: string;
  critical_sentences?: string[];
  image_data?: SnippetImageDetail;
}

export interface RichCitation {
  citation: string;
  metadata: {
    id?: string;
    id_type?: number;
    origin?: string;
    origin_type?: number;
    snippet_text?: string;
    fda_id?: string;
    pmid?: number;
    why_cited?: CitationScores;
    citation_detail: CitationDetail;
    critical_sentences?: string[];
    related_publications?: RichCitation[];
  };
  all_snippet_display_data?: SnippetDisplayData[];
}

export type Citation = string | RichCitation;

/*----------------------*/
/*    Search Results    */
/*----------------------*/

export interface ExternalSearchResult {
  dateLastCrawled: string;
  displayUrl: string;
  id: string;
  name: string;
  snippet: string;
  url: string;
  siteName?: string;
  datePublished?: string;
  language?: string;
  searchTags?: Record<string, any>[];
  thumbnailUrl?: string;
  sponsored?: boolean;
}

/*----------------------*/
/*  Article Components  */
/*----------------------*/

export interface ArticleSpan {
  text: string;
  hidden: boolean;
  citations: Citation[];
  is_block_quote: boolean;
  articlespanentry_set: SpanEntry[];
  metadata?: object;
  placeholder?: boolean;
  is_highlighted?: boolean;
}

export interface ArticleFigure {
  figurespan_set: ArticleSpan[];
  captionspan_set: ArticleSpan[];
  figure_html: string;
  flex?: string | number;
  placeholder?: boolean;
}

export interface ArticleFigureParagraph {
  articlefigure_set: ArticleFigure[];
  captionspan_set: ArticleSpan[];
  order_in_section: number;
  paragraph_title: string;
  link_name: string;
  placeholder?: boolean;
}

export interface ArticleParagraph {
  articlespan_set: ArticleSpan[];
  identifier: string;
  link_name: string;
  order_in_section: number;
  paragraph_title: string;
  placeholder?: boolean;
}

export interface ArticleSection {
  articleparagraph_set: ArticleParagraph[];
  articlefigureparagraph_set: ArticleFigureParagraph[];
  section_title: string;
  identifier: string;
  placeholder?: boolean;
  metadata?: Record<string, any>;
}

export interface ArticleData {
  title: string;
  byline: string;
  what_is_this_page_html: string;
  slug?: string;
  dt_published?: string;
  article_date?: string; // Deprecated, replaced by dt_published in the database but some old articles still use this
  last_modified_date?: string; // Last time the article was updated
  page_type: string;
  cms_type: string;
  medically_reviewed_by: string;
  medically_audited_by: string;
  audited_by: string;
  follow_up_questions?: string[];
  external_search_results?: ExternalSearchResult[];
  articlesection_set: ArticleSection[];
  intervention_as: { name_for_writing: string }[];
  condition_as: { name_for_writing: string }[];
  lead_image_url: string;
  hide_lead_image_in_body?: string;
  image_url: string;
  placeholder?: boolean;
  related_article_slugs?: string[];
  processed_input_query?: string;
  ui_flags?: UiFlags;
  metadata?: Record<string, any>;
}

export interface UiFlags {
  hide_processed_input_query?: string;
  processed_input_query_highlight_mode?: string;
  returned_partial_or_final_article?: boolean;
  qa_branch_type?: string;
}

export interface ArticleMetadata {
  title: string;
  subtitle?: string;
  slug?: string | null;
  imageURL?: string;
  datePublished?: string | null;
  categoryName?: string;
}

export interface ArticleSpecification {
  article: ArticleData;
  filename: string;
}

export const GUIDELINES_SECTION_ID = 'guidelines';
export const DRUGS_SECTION_ID = 'drugs';
export const DOCUMENT_PREVIEW_SECTION_ID = 'document-preview';
export const CALCULATOR_SECTION_ID = 'calculator';
export const CALCULATOR_UNSUPPORTED_SECTION_ID = 'calculator-unsupported';
export const ACCORDION_SECTION_ID = 'accordion';
export const KEY_FINDINGS_SECTION_ID = 'key_findings';
export const NEJM_REVIEW_SECTION_ID = 'nejm-review';
export const NEJM_LANDMARK_SECTION_ID = 'study-callout';
export const DEFAULT_TEXT_SECTION_ID = '';

export type SectionId =
  | typeof GUIDELINES_SECTION_ID
  | typeof DRUGS_SECTION_ID
  | typeof DOCUMENT_PREVIEW_SECTION_ID
  | typeof CALCULATOR_SECTION_ID
  | typeof CALCULATOR_UNSUPPORTED_SECTION_ID
  | typeof ACCORDION_SECTION_ID
  | typeof KEY_FINDINGS_SECTION_ID
  | typeof NEJM_REVIEW_SECTION_ID
  | typeof NEJM_LANDMARK_SECTION_ID
  | typeof DEFAULT_TEXT_SECTION_ID;
